import React, { useState } from 'react';
import Select from 'react-select';
import CreateCohortCertSettings from './CreateCohortCertSettings';
import UpdateCohortCertSettings from './UpdateCohortCertSettings';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { scholarshipCertificateTypeOptions } from '../utils/constants/constants';

function CohortCertSettings({ cohortId, toggleModal }) {
  const [certificateType, setCertificateType] = useState('');

  const {
    data: cohortCertSettingCheck,
    isLoading: isFetchingCohortCertSettingsCheck,
  } = useFetchDatav2(
    ['cohort-cert-settings-check', cohortId, certificateType],
    `/cohorts/${cohortId}/cert-settings/${certificateType}/check`,
    {},
    'Could not check for existence of cohort cert setting. Try again later',
    cohortId && certificateType
  );

  return (
    <div className="text-gray-700 space-y-2 mb-32">
      <div className="flex flex-col space-y-1">
        <label htmlFor="certificateType" className="text-base font-semibold">
          Select Certificate Type
        </label>
        <Select
          isClearable
          className="react-dropdown"
          classNamePrefix="dropdown"
          options={scholarshipCertificateTypeOptions}
          onChange={(selectedOption) => {
            setCertificateType(selectedOption?.value);
          }}
        />
      </div>
      {!isFetchingCohortCertSettingsCheck && cohortCertSettingCheck && (
        <div>
          {cohortCertSettingCheck?.data?.data?.cert_setting_exists ? (
            <UpdateCohortCertSettings
              cohortId={cohortId}
              certificateType={certificateType}
              toggleModal={toggleModal}
            />
          ) : (
            <CreateCohortCertSettings
              cohortId={cohortId}
              certificateType={certificateType}
              toggleModal={toggleModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default CohortCertSettings;
