export const scholarshipCertificateTypeOptions = [
  {
    label: 'CERTIFICATE_OF_PARTICIPATION',
    value: 'CERTIFICATE_OF_PARTICIPATION',
  },
  {
    label: 'CERTIFICATE_OF_ACHIEVEMENT',
    value: 'CERTIFICATE_OF_ACHIEVEMENT',
  },
];
