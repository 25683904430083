import React, { useState } from 'react';
import Select from 'react-select';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Modal from '../components/Modal';
import Spinner from '../components/spinner/Spinner';
import usePermissions from '../hooks/usePermissions';
import CreateGroup from '../components/CreateGroup';
import TableWrapper from '../utils/TableWrapper';
import CreateScholarshipGroupAssignment from '../components/CreateScholarshipGroupAssignment';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import useFetchDatav2 from '../hooks/useFetchDatav2';

const columns = [
  { title: 'Group Name', field: 'name' },
  { title: 'Max Group Size', field: 'size' },
  { title: 'Group Mode', field: 'join_mode' },
  {
    title: 'Created On',
    field: 'created_at',
    render: (row) => <p>{new Date(row.created_at).toLocaleDateString()}</p>,
  },
];

function Groups() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [
    createScholarshipGroupsModalOpen,
    setCreateScholarshipGroupsModalOpen,
  ] = useState(false);
  const [
    createScholarshipGroupAssignmentModalOpen,
    setCreateScholarshipGroupAssignmentModalOpen,
  ] = useState(false);

  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleCreateScholarshipGroupsModal = () => {
    setCreateScholarshipGroupsModalOpen(!createScholarshipGroupsModalOpen);
  };

  const toggleCreateScholarshipGroupAssignmentModal = () => {
    setCreateScholarshipGroupAssignmentModalOpen(
      !createScholarshipGroupAssignmentModalOpen
    );
  };

  const { data: cohortData } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const scholarshipCohortOptions = cohortData
    ? cohortData?.data.cohorts.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
      }))
    : [];

  const { data, isLoading } = useFetchDatav2(
    ['groups', cohortId],
    '/groups',
    { params: { cohort_id: cohortId } },
    "Couldn't get cohort groups. Please try again!",
    !!cohortId
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <div className=" flex space-x-2">
            {(permissions?.includes('CreateGroupAssignment') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleCreateScholarshipGroupAssignmentModal}
              >
                Create Group Assignment
              </button>
            )}

            {(permissions?.includes('CreateGroup') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleCreateScholarshipGroupsModal}
              >
                Create Scholarship Groups
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <div>
            <Select
              isClearable
              placeholder="Select Scholarship Cohort"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={scholarshipCohortOptions}
              onChange={(event) => {
                setCohortId(event?.value || null);
                setCohortName(event?.label || null);
              }}
            />
          </div>
          <div className="">
            {isLoading && cohortId ? (
              <div className="mt-5 bg-white py-5">
                <Spinner />
              </div>
            ) : (
              <TableWrapper
                title={`List of ${cohortName || ''} Groups`}
                columns={columns}
                // eslint-disable-next-line no-unsafe-optional-chaining
                data={data ? (data?.data).groups : []}
                onRowClick={(event, rowData) =>
                  navigate(`/groups/${rowData.name}/${rowData.id}`)
                }
              />
            )}
          </div>
        </div>
      </MainContent>
      <Modal
        modalOpen={createScholarshipGroupsModalOpen}
        toggleModal={toggleCreateScholarshipGroupsModal}
        title="Create Scholarship Groups"
      >
        <CreateGroup toggleModal={toggleCreateScholarshipGroupsModal} />
      </Modal>
      <Modal
        modalOpen={createScholarshipGroupAssignmentModalOpen}
        toggleModal={toggleCreateScholarshipGroupAssignmentModal}
        title="Create Groups Assignment"
      >
        <CreateScholarshipGroupAssignment
          toggleModal={toggleCreateScholarshipGroupAssignmentModal}
        />
      </Modal>
    </>
  );
}

export default Groups;
